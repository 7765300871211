<template lang="html">
  <section class="gestion-page">
    
      <bannerHead :dataTitle="'Vacantes'" ></bannerHead>
      <div class="box-filters container">
        <breacrumbs  :dataTitle="this.$route.query.slug ? store.offers[this.$route.query.slug].name : 'Vacantes'"></breacrumbs>
        <div class="filters" v-if="!!!this.$route.query.slug">
          <div class="box-tool-search">
            <label for="searchTool" hidden>Buscar</label>
            <input class="input-styled" name="buscar" type="text" placeholder="Buscar" id="searchTool" v-model="textSearch" />
            <button class="btn-search" @click="searchText" title="Buscar"></button>
            <button v-if="removeSearch" class="btn-clean" title="Limpiar búsqueda" @click="cleanText"></button>
          </div>
        </div>
      </div>
    
    <section class="container-success" v-if="store.loading">
      <div class="container-text gif">
        <Vue3Lottie :animationData="loadingAnimation" :height="auto" width="100%" />
      </div>
    </section>
    <section class="full-content  gestion"   id="menu" v-if="Object.entries(store.offers).length > 0 ">
        <div class="container">
          <panelOffers></panelOffers>
        </div>
    </section>
    <template v-else>
      <div class="container container-result">
        <p class="title-line -center">No hay resultados</p>
      </div>
    </template>


  </section>
</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  import panelGestion from '@/components/panel-gestion.vue'
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  import loadingAnimation from '@/lotties/loading.json'
  import panelOffers from '@/components/panel-offers.vue'
  const store = contentStore();
  export default {
    name: 'gestion-page',
    components: {
      bannerHead,
      breacrumbs,
      panelOffers,
      Vue3Lottie
    },
    setup() {
      return {
        store
      }
    },
    el: '#menu',
    data() {
      return {
        loadingAnimation,
        textSearch: null,
        removeSearch: false,
        show: false,
        showOffer:false,
      }
    },
    mounted() {
       store.loadOffers()
    },
    computed: {
      
    },
    methods: {
      showHide() {
        this.show = !this.show;
      },
     
      searchText() {
        this.store.loadOffers(
          { textSearch : this.textSearch }
        )
        this.removeSearch = true;
        this.showOffer = false;
      },
      cleanText() {
        this.textSearch = null;
        this.removeSearch = false;
        this.store.loadOffers({ textSearch : '' });
      },
      
   
    },
    watch: {
      
    },
  }


</script>

<style scoped lang="scss">
  .content-depart .title-line {
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: -10px;
  }
</style>